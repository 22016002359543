/* Dashboard page */
#root {
  min-height: 100vh;
  height: 100%;
}

div:has(> .analysis-page-wrapper) {
  display: flex;
  height: 100%
}

.analysis-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}

.app-wrapper-content {
  overflow: auto;
}

.analysis-page {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
}

.analysis-page-content {
  flex-grow: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 90%;
  z-index: 1;
  position: relative;
  overflow-x: hidden;
}

.analysis-page-tabs {
  overflow: auto;
  height: 100%;
}

.analysis-page-tabs__content {
  overflow: auto;
}

.analysis-page-tabs__tabs {
  margin-left: 8px !important;
  margin-top: 5px !important;
  height: 24px;
}

.analysis-page-content-top-panel {
  min-height: 94px;
  background-color: rgba(217, 217, 217, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.analysis-page-models {
  width: 192px;
  background-color: #eee;
  transition: width 0.2s;
}

.analysis-page-models-collapsed {
  width: 40px;
  transition: width 0.2s;
}

.analysis-tab-control {
  flex-grow: 1;
  overflow-y: auto;
  background-color: rgba(217, 217, 217, 0.5);
}

.analysis-tab-control__content {
  background-color: #f5f5f5;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.analysis-tab-control-tab {
  overflow-y: auto;
  position: relative;
  display: flex;
  margin: 0;
}

.analysis-tab-control-tab-button-wrapper {
  position: relative;
  display: flex;
  width: auto;
  user-select: none;
  cursor: pointer;
  border: 0;
  transition-duration: 0.3s;
  transition-property: background-color;
  padding: 8px 12px;
}

.analysis-tab-control-tab-button-wrapper:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.analysis-tab-control-tab.active > .analysis-tab-control-tab-button-wrapper {
  background-color: #f5f5f5;
}

.analysis-tab-control-tab.active .analysis-tab-control-tab-halo {
  height: 2px;
}

.analysis-tab-control-tab-button-content {
  position: relative;
  display: flex;
  flex-grow: 1;
  transform: none;
  opacity: 1;
  width: auto;
  height: 24px;
}

.analysis-tab-control-tab-halo {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  transition-duration: 0.3s;
  transition-property: height, width;
  background-color: #0696d7;
}

.analysis-tab-control-tab-button-content span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  overflow: hidden;
}

.analysis-tab-control-tab-button-content > p {
  display: inline-block;
  position: relative;
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  font-family: ArtifaktElement, sans-serif;
  text-align: center;
  line-height: 24px;
  color: #3c3c3c;
  padding-right: 0;
  padding-left: 24px;
}

.analysis-tab-control-tab.active .analysis-tab-control-tab-button-content > p {
  font-weight: 700;
}

.analysis-tab-content {
  padding: 10px;
}

.analysis-page-content-wrapper {
  overflow: auto;
  height: 100%;
}

.analysis-optimization-tab-content {
  margin: 6px;
}

.analysis-tab-overview-icon {
  height: 16px;
  width: 16px;
  display: block;
}

.analysis-tab-simulations-icon {
  height: 16px;
  width: 16px;
  display: block;
}

.analysis-tab-optimization-icon {
  height: 16px;
  width: 16px;
  display: block;
}

/* Default popup styles */

.insightx-modal-header {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(60, 60, 60, 0.25);
}

.insightx-modal-header > button:focus {
  outline: 0;
}

.insightx-modal-header > h5 {
  font-size: 16px;
  line-height: 22px;
  font-family: ArtifaktElement, sans-serif;
  font-weight: 500;
  color: #3c3c3c;
}

.insightx-modal .modal-body {
  padding: 16px 16px 0 16px;
}

.insightx-modal-footer {
  border: 0;
  padding: 16px 16px 16px 16px;
}

.insightx-modal-footer .MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
}

/* Factors popup */

.factors-modal {
  max-width: 636px;
  margin-top: 5%;
}

.factors-modal-content {
  width: 604px;
  display: flex;
  flex-direction: row;
  height: 400px;
}

.factors-modal-list-wrapper,
.factors-modal-factor-description {
  flex: 1;
  width: 286px;
  overflow-y: auto;
}

.factors-modal-factor-description {
  margin-left: 32px;
  background-color: #f5f5f5;
  overflow: hidden;
  padding: 220px 16px 16px 16px;
}

.factor-title,
.factor-description {
  display: inline-block;
  color: #3c3c3c;
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
}

.factor-title {
  font-weight: 700;
}

.factor-description {
  margin-left: 8px;
  margin-top: 4px;
  font-weight: 400;
}

ul.factors-category-list > li:not(:last-child) {
  margin-bottom: 24px;
}

ul.factors-list {
  margin-left: 16px;
  margin-right: 1px;
}

ul.factors-list > li:not(:last-child) {
  margin-bottom: 4px;
}

.factor-button {
  align-items: center;
  position: relative;
  display: flex;
  width: auto;
  border: 0;
  height: 24px;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
}

.factor-button.disabled {
  cursor: default;
}

.factor-button.selected {
  background-color: rgba(205, 234, 247, 0.6);
  border: 1px solid rgba(6, 150, 215, 0.5);
  border-radius: 2px 2px 2px 2px;
}

.factor-button.selected:hover {
  background-color: rgba(155, 213, 239, 0.7);
}

.factor-button:not(.disabled):not(.selected):hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.factor-button:not(.selected):not(.disabled):hover .factor-modal-icon-color {
  fill: #3c3c3c;
}

.factor-button.selected .factor-modal-icon-color {
  fill: #3c3c3c;
}

.factor-button > p {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  font-family: ArtifaktElement, sans-serif;
  color: #3c3c3c;
  padding-left: 32px;
  line-height: 24px;
}

.factor-button.selected > p {
  font-weight: 700;
}

.factor-checkmark-svg {
  display: inline-block;
  height: 16px;
  width: 16px;
  right: 8px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.factor-category-label {
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  color: #3c3c3c;
  user-select: none;
}

/* Advanced Search Modal popup*/

.advanced-search-modal-title {
  display: flex;
  gap: 0.3rem;
  white-space: nowrap;
}

@media screen and (max-width: 1050px) {
  .advanced-search-modal-title {
    white-space: normal;
  }
}

hr.settings-modal-divider {
  margin: 16px 0;
}

.settings-section-title {
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  color: #3c3c3c;
  user-select: none;
}

.radio-button-container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
}

.settings-label {
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #3c3c3c;
}

.settings-input-group {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.settings-input-text {
  width: 46px;
}

.settings-input-text .MuiSvgIcon-root {
  display: none;
}

.settings-input-text .MuiFormControl-root + div {
  padding: 0;
}

label.settings-utility-label {
  font-family: ArtifaktElement, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
}

/* icons position in factors image */
.hvac-background {
  background-position-x: 6.9%;
  background-position-y: -20%;
}

.lighting-background {
  background-position-x: 14.2%;
  background-position-y: -10%;
}

.plug-loads-background {
  background-position-x: 76.3%;
  background-position-y: -15%;
}

.wall-construction-background {
  background-position-x: 21.3%;
  background-position-y: -5%;
}

.roof-construction-background {
  background-position-x: 43.3%;
  background-position-y: -5%;
}

.glazing-construction-background {
  background-position-x: 0%;
  background-position-y: -5%;
}

.building-orientation-background {
  background-position-x: 50%;
  background-position-y: -5%;
}

.wwr-walls-background {
  background-position-x: 95.7%;
  background-position-y: 0%;
}

.window-shades-background {
  background-position-x: 101.5%;
  background-position-y: -5%;
}

.infiltration-background {
  background-position-x: 71.2%;
  background-position-y: -5%;
}

/* Remove ugly black outline */
button:focus {
  outline: 0;
}

.loader-title {
  font-family: ArtifaktElement, sans-serif;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  height: 24px;
  padding-top: 0.5em;
}

.loader-subtitle {
  font-family: ArtifaktElement, sans-serif;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  height: 24px;
  padding-top: 1em;
}

.sort-icon {
  fill: #808080;
}

.sort-icon-container {
  margin-right: 16px;
}

.analysis-page-rigth-top-panel {
  display: flex;
  align-items: center;
}

.analysis-page-left-top-panel {
  display: flex;
  align-items: center;
}

.flyout-menu {
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.flyout-menu-title-container {
  height: 24px;
  align-items: center;
  display: flex;
}

.flyout-menu-title {
  color: #9e9e9e;
  font-weight: 700;
  margin-left: 8px;
}

.flyout-menu-item-container {
  height: 24px;
  align-items: center;
  display: flex;
}

.flyout-menu-item-container:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.flyout-menu-item-container:active {
  background-color: rgba(128, 128, 128, 0.2);
}

.flyout-menu-item-text {
  margin-left: 4px;
}

.flyout-menu-item-text.selected {
  font-weight: 700;
}

.flyout-menu-item-check {
  fill: #0796d7;
  width: 16px;
  margin-left: 8px;
}

.flyout-override {
  cursor: pointer;
}

.flyout-override__panel {
  padding: 12px 0px 8px 0px !important;
}

/* Factor chart grid */
@media (min-width: 1024px) {
  .factor-chart-s {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 6px;
  }
}

@media (min-width: 1440px) {
  .factor-chart-m {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 6px;
  }
}

@media (min-width: 2560px) {
  .factor-chart-lg {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding: 6px;
  }
}

::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-corner {
  background-color: #eeeeee;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(128, 128, 128, 0.2) !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px 5px var(--lightestgrey);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px 5px rgba(128, 128, 128, 0.2);
  border: solid 4px transparent;
  border-radius: 10px;
}
.ecdetail-table [role='table']::-webkit-scrollbar {
  background-color: #f5f5f5;
}

div.MuiTooltip-popper div.MuiTooltip-tooltip {
  font-weight: normal;
}
