.top-nav-logo {
  min-width: 180px;
  align-self: center;
  height: 20px;
  margin-right: 8px;
}
.resized-top-nav-logo {
  min-width: 26px;
  align-self: center;
  height: 20px;
}

.top-nav-btn {
  font-size: 14px !important;
  margin-right: 0.5rem !important;
  padding: 0.5rem !important;
}
.top-nav-btn-container {
  margin-top: 0.5rem;
  display: flex;
}
.top-nav-container__top-nav-spacer {
  display: none;
}
.top-nav-interactions {
  min-width: 50vw;
  justify-content: end;
}
.nav-button {
  background: none;
  border: none;
  color: #007bff;
}

.nav-button:hover {
  color: #0056b3;
}
.nav-button:hover a {
  text-decoration: none;
}

hr.header-divider {
  height: 20px;
  margin: 0 8px;
}
