.ecDefinition-wrapper-value {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.ecDefinition-wrapper-novalue {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: baseline;
  border-bottom: 1px solid #faa21b;
  cursor: pointer;
  height: 100%;
}

.ecDefinition-wrapper-value p {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caret-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  height: 100%;
}

.ecDefinitionTooltipContent .tooltipFooter {
  margin: 10px;
  display: flex;
  justify-content: end;
  border-top: rgba(128, 128, 128, 0.5) solid 1px;
  padding-top: 5px;
}

.ecDefinitionTooltipContent .tooltipFooter span {
  color: white;
}

.ecDefinitionQuickSearch {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px auto 40px;
  grid-row-gap: 5px;
}

.WeaveFlyout-arrow-container-left-end {
  z-index: 1;
}

.iconContainer{
  z-index: 2;
}

.ecDefinitionQuickSearch .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  grid-row-gap: 5px;
  z-index: 2;
}

.ecDefinitionQuickSearch .content .ecDefinition-item {
  display: flex;
  justify-content: space-between;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  padding-right: 5px;
  align-items: center;
  cursor: pointer;
}

.ecDefinitionQuickSearch .searchContainer input {
  margin: 3px;
}

.ecDefinition-item-selected {
  background-color: rgba(205, 234, 247, 0.6);
  border: 1px solid #0696d7;
}

.ecDefinition-item:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.ecDefinitionQuickSearch .content .ecDefinition-item span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
}
.ecDefinitionQuickSearch .content .ecDefinition-item .ecDefinition-item-text {
  width: auto;
}

.ecDefinitionQuickSearch .content .ecDefinition-item p {
  font-family: ArtifaktElement, sans-serif;
  font-size: 12px;
  display: block;
  white-space: nowrap;
}

.ecDefinitionQuickSearch .footer {
  display: flex;
  padding-top: 7px;
  border-top: rgba(128, 128, 128, 0.35) solid 1px;
  justify-content: end;
  align-items: center;
  align-content: center;
  justify-items: center;
  z-index: 2;
}

.advancedSearchInput {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.advancedSearchTable {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.advancedSearchTable .MuiDataGrid-overlayWrapper {
  height: 100%;
}
.advancedSearchTable .MuiDataGrid-overlayWrapperInner {
  height: 100%;
}

div.advancedSearchTable div.MuiBox-root {
  height: 100%;
}

.advancedSearchTable .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.advancedSearchAddDefinition {
  display: flex;
  width: auto;
  align-items: end;
  cursor: pointer;
  justify-content: flex-start;
  height: 50px;
}

.advancedSearchTable .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell span p {
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}
.advancedSearchAddDefinition .MuiButtonBase-root.MuiButton-root {
  padding: 0;
  margin: 0;
}

.advancedSearchAddDefinition svg {
  border-radius: 10px;
  border: 2px solid #006eaf;
  padding: 1px;  
  width: 16px;
}

.advancedSearchAddDefinition p {
  height: 15px;
}

.advancedSearchContainer {
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  height: 98%;
}

.advancedSearchTable #advancedIconsDataGrid  {
  overflow: visible;
}

.advancedSearchDefinitionManagement {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.advancedSearchDefinitionManagement#advancedCellIcons {
  overflow: visible;
}

.advancedSearchDefinitionManagement div {
  padding: 0px 4px;
}

[aria-rowindex='1'] div.MuiDataGrid-columnHeader--moving {
  background-color: transparent;
}

.MuiDataGrid-cell .advancedSearchDefinitionManagement div .MuiSvgIcon-root {
  margin-right: 0;
}

.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row div[data-colindex='5'] {
  display: flex;
  justify-content: center;
}

span.MuiTooltipWrapper{
  cursor: pointer;
}

span.MuiDefinitionCellContet {
  width: 100%;
  display: flex;
  align-items: center;
}

span.MuiTooltipWrapperIcon span.MuiDefinitionCellContet {
  width: 100%;
}

span.MuiTooltipWrapper span {
  width: 100%;
}

span.MuiTooltipWrapperIcon span {
  width: 100%;
  margin-right: 0 !important;
  height: 15px;
}

span.MuiTooltipWrapperIcon {
  border-bottom: 1px solid #faa21b;
  cursor: pointer;
}

.MuiTooltipWrapperIcon .MuiDefinitionCellContet > span {
  flex-shrink: 1;
}

.MuiDataGrid-pinnedRowsRenderZone .MuiDataGrid-row:hover .MuiDataGrid-cell:not([data-field='__reorder__'])::before {
  background: transparent !important;
}

.containerContentTooltip {
   flex-shrink: 0;
}
div.MuiIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiCustom-sub-row {
  background-color: #f5f5f5;
}

div.MuiDataGrid-virtualScrollerRenderZone div.MuiCustom-sub-row:hover {
  background-color: #f5f5f5;
}

div.Mui-selected {
  border: none !important;
}

.hig-table-wrapper {
  height: 100%;
}

.qs-focus {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0);
}

.qs-unfocus {
  display: none;
}

.bannerContext {
  width: 100%;
  position: relative;
  z-index: 0;
}

.bannerContext > .MuiAlert-root {
  position: relative;
  z-index: 999;
}

.MuiAlertWeave-innerAction {
  padding-right: 24px;
  margin-top: 8px;
}
