.modal-fade {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(240, 240, 240, 0.95);
}

/* #region ConfirmDeletionPopup */
.confirm-deletion-popup {
    position: fixed;
    min-height: 185px;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    border: 1px solid #f5f6f7;
    background: #ffffff;
    padding: 0 10px;
    text-align: center;
    z-index: 20000;
    max-width: 500px;
}

    .confirm-deletion-popup .title {
        color: #084053;
        font-size: 1.8rem;
        border-bottom: 1px solid #0f7193;
        padding: 8px 0;
        line-height: 1em;
    }

    .confirm-deletion-popup .sub-title {
        margin: 15px 15px 10px 15px;
        font-size: 1.6rem;
        line-height: 1em;
        color: #7d7d7d;
    }

    .confirm-deletion-popup .name {
        font-size: 1.6rem;
        font-family: 'RobotoCondensed-Bold';
        color: #7d7d7d;
        -ms-word-break: break-all;
        word-break: break-all;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .confirm-deletion-popup .icon-delete {
        top: 5px;
        right: 10px;
        position: absolute;
        font-size: 2.2rem;
        color: #0f7193;
        cursor: pointer;
    }

    .confirm-deletion-popup .delete-btn {
        margin-top: 40px;
        margin-bottom: 20px;
        font-family: RobotoCondensed;
    }

.confirm-deletion-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    opacity: 0.95;
    top: 0px;
    left: 0px;
    z-index: 1001;
}

/* #endregion */