/*#region project list */

#project_list_container {
  margin-top: 0;
  padding: 0;
  max-width: unset;
}

.widget {
  font-family: 'RobotoCondensed';
  background: #f5f6f7;
  box-shadow: 0 0 2px rgba(69, 69, 70, 0.2);
  background: #f5f6f7;
}

.widget.project-widget.clearfix {
  padding-top: 0.1px;
}

.no-touch .widget:hover {
  outline: 1px solid #0f7193;
}

.landing-projects-title {
  font-family: RobotoCondensed;
  font-size: 2.8em;
  color: #084053;
  text-align: center;
  padding: 80px 0 36px 0;
}

.landing-projects {
  background: #fbfbfd;
  width: 100%;
}

.landing-projects #project_list_container {
  margin-top: 0;
  padding-top: 20px;
  position: relative;
  overflow: visible;
  max-height: none;
}

.project-widget-container {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(1, 1fr);
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

@media (min-width: 800px) {
  .project-widget-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1050px) {
  .project-widget-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1350px) {
  .project-widget-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1800px) {
  .project-widget-container {
    grid-template-columns: repeat(5, 1fr);
  }
}


.project-widget {
  width: 294px;
  font-size: 10px;
}

.project-widget > a {
  margin: 5px;
  display: block;
}

.project-widget .progress-bar {
  padding: 0.7rem 2rem 0.7rem 2rem;
  background-color: inherit;
}

.project-widget .progress-bar .empty-progress-bar {
  height: 30px;
}

.project-widget .upload-img {
  margin: 5px;
  overflow: hidden;
  height: auto;
  width: 284px;
  background: #ffffff;
  padding: 30px 0 0 0;
  text-align: center;
  font-size: 1.2em;
  display: none;
  position: relative;
}

.project-widget .upload-img .icon-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0f7193;
  cursor: pointer;
}

.project-widget .upload-img #imageBrowse {
  display: none;
}

.project-widget .upload-img .choose-file {
  font-size: 1em;
  color: #7d7d7d;
  border: 1px solid #cccbcb;
  padding: 7px 12px;
  max-width: 150px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  -ms-word-break: keep-all;
  word-break: keep-all;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-widget .upload-img p {
  margin: 0;
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  color: #7d7d7d;
}

.project-widget .upload-img .progressbar {
  height: 13px;
  width: 212px;
  margin: 0 auto;
}

.project-widget .upload-img .percentage-text {
  padding: 10px 0 0 0;
}

.project-widget .upload-img .button-group {
  margin-top: 10px;
  margin-bottom: 20px;
}

.project-widget .upload-img.uploading .progressbar {
  background: url(../img/image-loader.gif) no-repeat;
  background-size: 100% 100%;
}

.project-widget .create-btn {
  height: 290px;
  float: left;
  overflow: hidden;
  cursor: pointer;
  color: rgba(15, 113, 147, 0.3);
  width: 100%;
  border: 5px solid #eee;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-touch .project-widget .create-btn:hover,
.no-touch .design-option-widget .create-btn:hover {
  background: #ffffff;
  color: #0f7193;
  outline: none;
}

.project-widget .create-btn span {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 2.8em;
}

.project-widget .create-btn label,
.design-option-widget .create-btn label {
  font-size: 1.6em;
  line-height: 1.8em;
  text-align: center;
  display: block;
  cursor: pointer;
}

.project-widget .summary {
  padding: 10px 0 10px 10px;
  position: relative;
  background: #ffffff;
}

.project-widget .summary a {
  -ms-word-break: keep-all;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 16.5em;
  height: 19px;
  display: inline-block;
  text-decoration: none;
  font-size: 1.6em;
  color: #0f7193;
  cursor: pointer;
}

.project-widget .summary .last-update-time {
  padding-top: 4px;
}

.project-widget .summary .last-update-time span[class^='icon-'],
.project-widget .summary ul.stats span[class^='icon-'] {
  padding-right: 3px;
  font-size: 1.6em;
  color: #7d7d7d;
  vertical-align: middle;
}

.project-widget .summary span.text {
  display: inline-block;
  font-size: 1.2em;
  color: #444;
  line-height: 16px;
  vertical-align: middle;
}

.project-widget .summary ul.stats {
  margin-top: 18px;
  margin-bottom: 0;
  padding: 0;
}

.project-widget .summary ul.stats li {
  float: left;
  padding-right: 20px;
}

.project-widget .summary .texteditor-container {
  margin: -3px 0 0 -5px;
}

.project-widget.sample > a::after,
.project-widget.shared > a::after {
  display: block;
  position: absolute;
  z-index: 2;
  width: 96px;
  height: 96px;
  top: 0;
  right: 0;
  content: ' ';
}

.project-widget.sample > a::after {
  background: url('../img/sample.png') no-repeat;
}

.project-widget.shared > a::after {
  background: url('../img/shared.png') no-repeat;
}

.project-widget-gutter {
  width: 20px;
}

@-moz-document url-prefix() {
  .project-widget {
    border: 1px solid transparent;
  }

  .no-touch .project-widget:hover {
    border: 1px solid #0f7193;
    outline: none;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

body {
  margin: 0;
  padding: 1rem;
}

img {
  vertical-align: middle;
  width: 100%;
}
