.icon-text-button {
    position: relative;
    display: flex;
    width: auto;
    user-select: none;
    border: 0;
    padding-left: 4px;
    padding-right: 4px;
}

.icon-text-button-content {
    position: relative;
    display: flex;
    transform: none;
    width: auto;
    height: 24px;
    cursor: pointer;
}

.icon-text-button-content > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
    overflow: hidden;
}

.icon-text-button-content > p {
    display: inline-block;
    position: relative;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    font-family: ArtifaktElement,sans-serif;
    text-align: center;
    line-height: 20px;
    color: #3c3c3c;
    padding-right: 0;
    padding-left: 26px;
    padding-top: 2px;
}
