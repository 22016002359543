.MuiMenuItemWeave-content {
  display: block !important;
}

.Mui-selected {
  background-color: rgba(205, 234, 247, 0.6) !important;
  border: 1px solid rgba(6, 150, 215, 0.5) !important;
  border-radius: 3px !important;
}

.MuiWeave-high.MuiDataGrid-row.Mui-selected.MuiCustomLast-selected {
  background-color: none !important;
  border: 0 !important;
  border-radius: 0 !important;
}
