.serviceUnavailableWrapper {
  width: 100%;
  height: 100%;
}

.errorComponentWrapper {
  height: 100vh;
  width: 100%;
}

.serviceUnavailableContentWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.serviceUnavailableContentWrapper svg {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.wrapText {
  white-space: normal;
}

.advancedSearchNoResults {
  position: absolute;
  width: 100%;
}

.advancedSearchNoResults svg {
  margin-top: 2rem;
}




