/* #region project detail */

body.project-detail .tooltip-inner {
    max-width: 300px;
}

    body.project-detail .tooltip-inner em {
        font-style: italic;
    }

#design_options {
    padding: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    position: relative;
    margin-right: 20px;
    height: 100%;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

    #design_options .swith-menu-container {
        color: #0f7193;
        font-size: 0;
        width: 22px;
        margin-top: 22px;
    }

        #design_options .swith-menu-container .action-menu2 {
            display: inline-block;
            font-size: 22px;
            cursor: pointer;
        }

            #design_options .swith-menu-container .action-menu2.selected {
                color: #87b8c9;
                cursor: default;
                display: none;
            }

    #design_options .thumbnail-view,
    #design_options .list-view {
        max-height: calc(100% - 68px);
        padding: 1px 0 0 1px;
    }

    #design_options .thumbnail-view {
        overflow-y: auto;
    }

@media (max-height: 400px) {
    #design_options .icon-vertical-kebab-menu.action-menu-trigger,
    #design_options.single-selection .caret-wrapper {
        display: none;
    }
}

#design_options .list-view {
    background-color: #fff;
    padding: 0;
}

#design_options #model_list_table_container,
#design_options #model_list_table_container > div,
#design_options #model_list_table_container > div table {
    height: 100%;
}

    #design_options #model_list_table_container .table-action-menu {
        position: absolute;
        right: 120px;
        top: -38px;
        width: 150px;
    }

.row.no-gutter > [class*='col-'].design-option-widget {
    padding: 0;
    flex: unset;
    max-width: unset;
}

.design-option-widget {
    width: 283px;
    height: 200px;
    margin: 0 15px 15px 0;
    padding: 0;
    position: relative;
}

    .design-option-widget#add_model {
        outline: none;
    }

    .design-option-widget .create-btn {
        height: 200px;
        float: left;
        overflow: hidden;
        cursor: pointer;
        color: rgba(15, 113, 147, 0.3);
        width: 100%;
        border: 5px solid #eee;
        background-color: #f9f9f9;
    }

        .design-option-widget .create-btn span {
            margin-top: 70px;
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 2.8em;
        }

    .design-option-widget .design-option-thumbnail > a {
        height: 168px;
        display: block;
        text-decoration: none;
        text-align: center;
    }

        .design-option-widget .design-option-thumbnail > a > img {
            height: 100%;
        }
.design-option-thumbnail-progress-ring {
    height: 100%;
    display: flex;
    justify-content: center;
}
    .design-option-widget .name {
        display: block;
        height: 32px;
        line-height: 32px;
        font-family: 'RobotoCondensed';
        font-size: 1.6em;
        color: #0f7193 !important;
        padding-left: 10px;
        padding-right: 10px;
        background: #ffffff;
        -ms-word-break: keep-all;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-align: left;
        text-decoration: none;
    }

    .design-option-widget.with-icon .name {
        padding-right: 42px;
    }

    .design-option-widget .notification-icon {
        position: absolute;
        right: 8px;
        bottom: 6px;
        color: red;
        font-size: 2.2em;
    }

        .design-option-widget .notification-icon.icon-error {
            color: #ff3300;
        }

        .design-option-widget .notification-icon.icon-warning {
            color: #ffa600;
        }

    .design-option-widget .notification-overlay {
        display: block;
        width: 100%;
        height: calc(100% - 32px);
        background-color: #f5f6f7;
        z-index: 1;
        position: absolute;
        top: 0;
        opacity: .85;
        z-index: 1;
    }

    .design-option-widget .cost-range {
        position: absolute;
        top: 10px;
        left: 10px;
        text-align: center;
        z-index: 2;
    }

        .design-option-widget .cost-range.disabled {
            z-index: 0;
        }

        .design-option-widget .cost-range p {
            margin: 0;
            padding: 0;
            line-height: 100%;
        }

        .design-option-widget .cost-range .min-max {
            font-family: 'Roboto';
            font-size: 1.2em;
            color: #7d7d7d;
            letter-spacing: -0.02em;
            margin-bottom: 5px;
        }

        .design-option-widget .cost-range .mean {
            font-family: 'RobotoCondensed-Bold';
            font-size: 2.2em;
            color: #444;
            letter-spacing: -0.02em;
            margin-bottom: 4px;
        }

        .design-option-widget .cost-range .unit {
            font-family: 'Roboto';
            font-size: 1em;
            color: #7d7d7d;
            letter-spacing: -0.02em;
        }

    .design-option-widget .texteditor-container {
        margin: 3px 0 0 5px;
    }

    .design-option-widget .action-menu-trigger {
        right: 0;
        padding: 20px 10px 20px 0;
        z-index: 3;
        top: calc(50% - 30px);
    }

    .design-option-widget .loader {
        width: 100%;
        height: calc(100% - 32px);
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-top: 0;
        background-color: #f5f6f7;
        opacity: .85;
        z-index: 3;
    }

        .design-option-widget .loader .circle {
            width: 60px;
            height: 60px;
            margin: 20% auto;
        }

    .design-option-widget .badger-wrapper {
        position: absolute;
        height: 120px;
        width: 120px;
        overflow: hidden;
        top: 0;
        right: 0;
        z-index: 2;
    }

        .design-option-widget .badger-wrapper .utility-badger {
            position: relative;
            font-weight: bold;
            font-size: 11px;
            right: -10px;
            top: 32px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            background-color: #999999;
            color: #F8F8F8;
            width: 140px;
            height: 20px;
            line-height: 20px;
            text-align: center;
        }

#design_options.multi-selection .design-option-widget .action-menu-trigger {
    display: none;
}

.design-option-overlay-tooltip {
    margin-top: 90px !important;
}

#design_option_compare_content {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-right: 20px;
}

#design_option_compare #design_option_compare_chart .dimmed-tooltip {
    font-family: RobotoCondensed-Light;
    margin-top: 4px;
    display: block;
    margin-left: 4px;
}

#design_option_compare .scenarios {
    padding: 25px 0 10px;
    font-family: 'RobotoCondensed';
    font-size: 1.6em;
}

    #design_option_compare .scenarios .drop-down-list {
        margin-left: 10px;
    }

.design-option-widget .model-checkbox {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
}

    .design-option-widget .model-checkbox span {
        display: none;
    }

.no-touch .design-option-widget:hover .model-checkbox span {
    display: inline-block;
}

.design-option-widget .selected-overlay {
    display: none;
}

.design-option-widget.selected .selected-overlay {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: 2px solid #0f7193;
    background: rgba(15,113,147,0.2);
    display: block;
}

.no-touch .design-option-widget:hover .selected-overlay {
    pointer-events: none;
}

.no-touch .design-option-widget:hover .cost-range.disabled ~ .selected-overlay {
    outline: 1px solid #0f7193;
    background: none;
}

#design_options #action_menu {
    width: 150px;
    margin-top: 15px;
    margin-right: 15px;
}

    #design_options #action_menu .table-action-menu {
        display: none;
    }

#design_options.multi-selection #action_menu .table-action-menu {
    display: block;
}

#design_options #action_menu .table-action-menu {
    width: 100%;
}

/* #endregion */

/*#region tabs */

.project-tabs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    background: #f5f6f7;
    border-left: 2px solid #0f7193;
    box-shadow: -2px 0 2px rgba(1, 1, 1, 0.2);
    margin-top: 67px;
}

    .project-tabs-container .tab-content {
        height: 100% !important;
        background: #f5f6f7;
        z-index: 102;
        overflow: hidden;
    }

        .project-tabs-container .tab-content.collapse {
            display: none;
        }

            .project-tabs-container .tab-content.collapse.in {
                width: 560px;
                display: block;
            }

        .project-tabs-container .tab-content.collapsing {
            -webkit-transition: width 0.35s;
            transition: width 0.35s;
            display: block;
        }

            .project-tabs-container .tab-content.collapsing[aria-expanded = true] {
                width: 560px;
            }

            .project-tabs-container .tab-content.collapsing[aria-expanded = false] {
                width: 0;
            }

    .project-tabs-container .v-tabs {
        background: #444444;
        width: 44px;
        margin: 0;
        z-index: 103;
    }

        .project-tabs-container .v-tabs > li {
            margin-bottom: 2px;
            background: #7d7d7d;
        }

            .project-tabs-container .v-tabs > li:first-child {
                display: none;
            }

            .project-tabs-container .v-tabs > li > a {
                text-align: center;
                color: #ffffff;
                font-size: 3em;
                text-decoration: none;
                display: block;
                height: 44px;
                line-height: 1.4667em;
                position: relative;
            }

.no-touch .project-tabs-container .v-tabs > li > a:hover {
    color: #7d7d7d;
    background: #e8e8e8;
}

.project-tabs-container .v-tabs > li > a > .bubble {
    display: inline-block;
    position: absolute;
    top: -3px;
    left: -10px;
    border: 1px solid #ffffff;
    background: #ee8822;
    width: 19px;
    height: 19px;
    line-height: 19px;
    font-size: 0.4em;
    font-family: RobotoCondensed-Bold;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    z-index: 200;
}

.project-tabs-container .v-tabs > li.active > a {
    color: #7d7d7d;
    background: #f5f6f7;
}

.project-tabs-container .tab-content #design_option_compare {
    width: 560px;
}

    .project-tabs-container .tab-content #design_option_compare.active {
        position: static;
    }

.project-tabs-container .tab-toggle {
    width: 15px;
    position: relative;
    cursor: pointer;
    z-index: 104;
}

.no-touch .project-tabs-container .tab-toggle:hover {
    background-color: rgba(15,113,147,0.2);
}

.project-tabs-container .tab-toggle span.arrow {
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    border-right: 4px solid #0f7193;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: none;
}

.tab-toggle[aria-expanded = true] span.arrow {
    -webkit-transform: rotate(0.5turn);
    transform: rotate(0.5turn);
}



.no-flexbox .project-tabs-container {
    display: table-cell; /*ie9 shiv*/
}

.v-tab-panel {
    width: 100%;
    height: 100%;
    padding: 0 20px 20px;
    border-style: none;
    background: #F5F6F7;
}

    .v-tab-panel .v-tab-header {
        height: 40px;
        border-bottom: 1px solid #0F7193;
        width: calc(100% - 20px);
    }

        .v-tab-panel .v-tab-header .title,
        .v-tab-panel .v-tab-header .icon-maximize {
            text-align: left;
            opacity: 1;
        }

        .v-tab-panel .v-tab-header .title {
            font: normal 2em/40px 'RobotoCondensed';
            color: #0F7193;
            letter-spacing: 0;
        }

        .v-tab-panel .v-tab-header .icon-maximize {
            float: right;
            font: normal 2.2em/40px 'gbs-icons';
            Color: #7d7d7d;
            text-align: left;
            letter-spacing: -0.03em;
            cursor: pointer;
        }

    .v-tab-panel #project_comments_container, .v-tab-panel #project_members {
        padding-top: 20px;
    }

#design_option_compare,
#project_members,
#project_comments {
    height: 100%;
}

#project_comments_container {
    height: calc(100% - 40px);
}

/*#endregion */
