.legend {
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-right: 10px;
    flex-shrink: 0;
}

    .legend label {
        white-space: nowrap;
        padding-right: 4px;
        padding-left: 12px;
        font-size: 12px;
        font-weight: bold;
    }

.legend-item {
    overflow: auto;
    width: -webkit-fill-available;
    flex-grow: 1;
}

.item {
    height: 32px;
    position: relative;
    padding-left: 16px;
    padding-top: 7px;
}

    .item div {
        float: left;
        height: 16px;
        width: 16px;
        clear: both;
        position: relative;
        border: 1px solid white;
    }

    .item h1 {
        font-family: ArtifaktElement,sans-serif;
        font-size: 12px;
        padding: 1px 3px 1px 3px;
        padding-left: 3%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .default:hover {
        background-color: rgba(128, 128, 128, 0.1);
        cursor: pointer;
    }

    .hover {
        background-color: rgba(128, 128, 128, 0.1);
        cursor: pointer;
    }

    .selected {
        background-color: rgba(205, 234, 247, 0.6);
        border: 1px solid rgba(6, 150, 215, 0.5);
    }

    .selected:hover {
        background-color: rgba(155, 213, 239, 0.7);
        border: 1px solid rgba(6, 150, 215, 0.5);
    }

    .disabled {
        opacity: .5;
    }

.lmv-legend-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.lmv-legend-toggle {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

    .lmv-legend-toggle::before {
        content: "";
        position: absolute;
        left: 2px;
        right: 2px;
        top: 0;
        border-top: 1px solid lightgray;
    }

.show-legend-label {
    margin-top: auto;
    transform: rotate(270deg);
    display: flex;
    padding: 5px 5px 5px 10px;
    white-space: nowrap;
    color: #3c3c3c;
    font-size: 12px;
    font-weight: 400;
}

.lmv-legend-toggle-icon {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.lmv-legend-toggle-logo {
    height: auto;
    width: 100%;
}

.chart-legend-title {
    font-size: 12px;
    padding: 8px 0 8px 12px;
    font-family: ArtifaktElement, sans-serif;
    font-weight: bold;
}

    .chart-legend-title-others {
        padding-top: 0px;
    }
