.action-menu-trigger {
    font-size: 2.2em;
    position: absolute;
    top: calc(50% - 11px);
    right: 0;
    cursor: pointer;
    color: #7d7d7d;
}

.popover {
    border-radius: 0;
    min-width: 160px;
    box-shadow: 6px 6px 3px rgba(0,0,0,.05);
    border: 1px solid #e6e6e6;
    font-family: 'RobotoCondensed';
}

.popover-body {
    color: #989898;
    padding: 0;
}

    .popover-body li {
        cursor: pointer;
        margin: 0;
        padding: 6px 10px;
        font-size: 13px;
        position: relative;
    }

        .popover-body li:hover {
            background: #e2eef2;
            -moz-transition: all .2s ease 0;
            -o-transition: all .2s ease 0;
            -webkit-transition: all .2s ease 0;
            transition: all .2s ease 0;
        }

        .popover-body li .icon {
            float: left;
            font-size: 17px;            
            padding-right: 7px;
        }

        .popover-body li .text {
            line-height: 38px;
            height: 38px;
            display: inline-block;
            font-family: 'RobotoCondensed';
            letter-spacing: -.03em;
        }

.bs-popover-auto[x-placement^=right] > .arrow::before, .bs-popover-right > .arrow::before {
    border-right-color: #e6e6e6;
}