.main-wrapper .main-dashboard {
    max-width: 1620px;
}

body .main-dashboard {
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px 10px 0px;
    box-sizing: border-box;
    display: block;
}

/* #region body */
body.ECR {
    font: 12px/18px 'Helvetica Neue', Helvetica, Arial;
    padding-top: 0;
}

    body.ECR .confirm-deletion-popup {
        top: 200px;
    }

    body.ECR.error {
        height: 100%;
        background: none;
    }

        body.ECR.error div.main {
            height: 100%;
        }

/* Override to look like the other Insight pages */
body .walkthrough-component__overlay {
    font: 10px 'Roboto', Helvetica, Arial;
}

body .ecr-main {
    min-width: 480px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    position: relative;
    overflow-x: hidden;
}

.ecr-main .container,
.ecr-main .container-fluid {
    padding: 0 10px;
}

body div.ecr-main .header {
    height: 50px;
    padding: 13px 20px 0 20px;
    position: relative;
}
/* #endregion */

/* #region widget base */
.dashboard-widget-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 50%
}

    .dashboard-widget-wrapper:after {
        padding-top: 100%;
        display: block;
        content: '';
    }

.dashboard-main-widget .dashboard-widget-wrapper:after {
    padding-top: 32.393%;
}

.dashboard-main-widget .dashboard-widget-wrapper .dashboard-widget .dashboard-widget-chart.error > div.message {
    background-size: 10%;
    padding-top: 7%;
}

.dashboard-main-widget #bookmark_compare .dashboard-widget .dashboard-widget-chart {
    background: rgb(238,238,238) !important;
}

    .dashboard-main-widget #bookmark_compare .dashboard-widget .dashboard-widget-chart .chart {
        padding: 2.17% 0 0 0;
        width: 100%;
        padding-left: 10%;
        padding-right: 28.9%;
        height: 100%;
        display: inline-block;
    }

        .dashboard-main-widget #bookmark_compare .dashboard-widget .dashboard-widget-chart .chart .xaxis-label {
            max-width: 125px;
            -ms-word-break: keep-all;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

    .dashboard-main-widget #bookmark_compare .dashboard-widget .dashboard-widget-chart .legend,
    .main-widget #building_form .widget .legend {
        position: absolute !important;
        width: 21.6%;
        min-width: initial;
        max-width: initial;
        min-height: initial;
        z-index: 4;
        bottom: 10px;
        right: 2.7%;
    }

.dashboard-main-widget #building_form .dashboard-widget .legend {
    width: 20%;
    bottom: 30px;
    min-width: 140px;
    max-width: 280px;
}

.dashboard-widget {
    border-color: #DDDDDD #CCCCCC #A5A5A5;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background: #F7F7F7;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 17px;
    font-size: 12px;
}

    .dashboard-widget .dashboard-widget-header {
        height: 2.2em;
    }

        .dashboard-widget .dashboard-widget-header h3 {
            font-weight: bold;
            color: #231f20;
            margin-top: 0;
            margin-bottom: 0;
        }

    .dashboard-widget.widget-with-sub-title .dashboard-widget-header {
        height: 3.4em;
    }

    .dashboard-widget .dashboard-widget-chart {
        height: calc(100% - 2.2em) !important;
        position: relative;
    }

.widget.widget-with-sub-title .widget-chart {
    height: calc(100% - 3.4em);
    position: relative;
}
/* #endregion */

/* #region Widget Dock */
.dashboard-main-widget .dashboard-widget-bottom-left {
    position: absolute;
    left: 40px;
    bottom: 65px;
    z-index: 1;
    background: #FFF;
    padding: 2px;
    border-color: #DDDDDD #CCCCCC #A5A5A5;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.mini-widget .dashboard-widget-header h3 {
    font-size: 10px;
    font-weight: bold;
    color: #231F20;
    text-align: left;
    margin-top: 3px;
    margin-bottom: 5px;
    line-height: 10px;
}
/*#endregion */

/* #region Location / Building Form */
.location .dashboard-widget-header {
    border-bottom: none;
}

.location .map-overlay {
    position: absolute;
    z-index: 1;
}

.location .widget-chart,
.mini-location .widget-chart {
    border: 1px solid #CCC;
}

.mini-widget .widget-chart {
    width: 68px !important;
    height: 67px !important;
}

.mini-location .mini-ecr-pin img {
    width: 16px;
    height: 17px;
    margin-top: 18px;
    margin-left: 10px;
}

.MicrosoftMap .LogoContainer {
    margin-left: 85px;
    margin-bottom: 5px;
}
/* #endregion */

.minimize-widget {
    font-size: 10px;
    padding: 2.94118px;
}

.chart-widget {
    height: 100%;
    border-style: solid;
    border-radius: 3px;
    padding: 0;
    background-color: rgba(255,255,255,0.8);
    border: 1px solid rgba(60,60,60,0.1);
}

.chart-widget-title-container {
    border-bottom: 1px solid rgba(60,60,60,0.1);
    padding: 12px 0;
    display: flex;
    justify-content: flex-start;
}

.chart-widget-chart-container {
    padding: 4px 4px 0 4px;
}

.chart-widget-title {
    color: #3c3c3c;
    font-size: 16px;
    font-family: ArtifaktElement, sans-serif;
    font-weight: 600;
    margin-left: 24px;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.chart-widget-chart-container-loader {
    padding-top: 20%;
    padding-bottom: 20%;
}

@media screen and (max-width: 1300px) {
    .chart-widget-chart-container-loader {
        padding-top: 10%;
        padding-bottom: 10%;
    }
}
