.texteditor-container {
    width: 260px;
    height: 25px;
    position: relative;
    border: 1px solid #5B9ED2;
    box-shadow: 0 0 3px 0 rgba(91,158,210,1);
    border-radius: 3px;
    background: #FFFFFF;
}

    .texteditor-container form input,
    .texteditor-container form button {
        position: absolute;
        font-style: normal;
        text-align: left;
        letter-spacing: 0;
        border-style: none;
        background: transparent;
        height: 100%;
        outline: none;
        opacity: 1;
        top: 0;
    }

    .texteditor-container form input {
        width: 226px !important;
        left: 4px;
        font-family: RobotoCondensed;
        font-size: 1.6em;
        line-height: 100%;
        color: #7D7D7D;
    }

    .texteditor-container button {
        width: 30px;
        right: 0;
        font-family: gbs-icons;
        font-size: 2.2rem;
        color: #0F7193;
        border-radius: 4px;
    }

        .texteditor-container button.icon-ok:before {
            position: absolute;
            top: 0;
        }

        .texteditor-container button.submitting {
            background: url('../img/icon_preloader.gif') #FFFFFF no-repeat center;
        }
