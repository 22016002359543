/* #region bootstrap */
.container {
    width: 100%;
}

.container,
.container-fluid {
    padding: 0 20px;
}

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

    .row.no-gutter > [class*='col-'] {
        padding: 10px;
    }

.btn {
    border-color: #CCC;
}

/* #endregion */

.main-wrapper .main {
    max-width: 1620px;
}

.app-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
}

body .main-wrapper .main {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 48px;
    box-sizing: border-box;
    display: block;
}

body {
    position: relative; /* iPad needs this positioning */
    background-color: #FFF;
    font: 10px 'Roboto', Helvetica, Arial;
    -webkit-touch-callout: none;
}

.float-left {
    float: left;
}

html, body {
    min-height: 100%;
    height: 100%;
}

.main-content {
    height: calc(100vh - 160px);
    overflow: auto;
}

.no-entitlements-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1030px) {
    body .main-wrapper .main {
        height: 100%
    }

    .main-content {
        height: calc(100vh - 85px);
    }
}

/* #region page background */

body.project-list .app-wrapper-content,
body.project-detail .app-wrapper-content {
    background: #fbfbfd;
}

body.error .app-wrapper-content {
    background: url('../img/hero-download.png') #F5F6F7 no-repeat center;
    background-size: cover;
}

/* #endregion */

/* #region breadcrumb */

.top-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.breadcrumb {
    font-size: large;
    font-weight: bold;
    padding: 0.8em 0.8em 0.8em 0;
    letter-spacing: 0;
    color: #3c3c3c;    
    margin: 0;
    background-color: transparent;
    border-radius: unset;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    display: unset;
}

    .breadcrumb .l1 {
        color: #084053;
    }

    .breadcrumb .l2 {
        color: #0f7193;
        font-size: 0.857em;
    }

        .breadcrumb .l2:before {
            /*content: '/ ';*/
            color: #404040;
        }

.right-icon {
    text-align: right;
    padding-right: inherit;
}

/* #endregion */

/* Override toast properties */
.css-1a77ee {
    bottom: 71px !important;
}
/*//////////////////*/
