/*collapse icon*/ 
.collapse-icon {
    fill: #808080;
    width: 16px;
    height: 16px;
    cursor:pointer;
}
    .collapse-icon:hover g {
        fill: #7C7C7C;
    }

    .collapse-icon:active g {
        fill: #7C7C7C;
    }

.collapse-icon-backgound {
    fill: none;
}
    .collapse-icon:hover .collapse-icon-backgound {
        opacity: 0.1;
        fill: #808080;
        enable-background: new;
    }
.collapse-icon:active .collapse-icon-backgound {
    opacity: 1;
    fill: #D8D8D8;
    enable-background: new;
}
/*collapse icon*/

/*expand icon*/
.expand-icon {
    fill: #808080;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

    .expand-icon:hover g {
        fill: #7C7C7C;
    }

    .expand-icon:active g {
        fill: #7C7C7C;
    }

.expand-icon-backgound {
    fill: none;
}

.expand-icon:hover .expand-icon-backgound {
    opacity: 0.1;
    fill: #808080;
    enable-background: new;
}

.expand-icon:active .expand-icon-backgound {
    opacity: 1;
    fill: #D8D8D8;
    enable-background: new;
}
/*expand icon*/ 

/* Factor Modal Icons */

.factor-modal-icon {
    height: 16px;
    width: 16px;
    margin-left: 8px;
}

.factor-modal-icon-color {
    fill: #7C7C7C;
}

.factor-modal-icon-rule {
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.factor-modal-icon-no-fill {
    fill: none;
}
    /* Factor Modal Icons */


.svg-hvac-id-4 {
    clip-path: url(#SVGID_HVAC_4_);
}

.sort-icon {
    height: 24px;
    width: 24px;
}