.projects-container {
    border: 1px solid #CDC5C4;
    width: 857px;
    height: 500px;
    position: absolute;
    top: auto;
    left: 0;
    top: 20%;
    right: 0;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    z-index: 20000;
}

.projects-wrapper {
    background-color: #f5f6f7;
    height: 100%;
    width: 100%;
}

.projects-header {
    padding: 16px 20px 14px 20px;
    position: relative;
    text-align: center;
}

    .projects-header .title {
        font-size: 1.8em;
        font-family: 'RobotoCondensed';
        color: #084053;
        line-height: 0.98em;
    }

    .projects-header .icon-delete {
        float: right;
        color: #0f7193;
        cursor: pointer;
        font-size: 2.2em;
        position: absolute;
        top: 13px;
        right: 17px;
    }

.projects-body {
    background-color: white;
    margin: 0px 20px;
    height: calc(100% - 117px);
    overflow-y: auto;
}

.projects-footer {
    padding: 20px 20px 0 20px;
    height: 70px;
    text-align: right;
}

.projects-grid .container-fluid {
    padding: 5px;
}
    .projects-grid .container-fluid .row.no-gutter > [class*='col-'] {
        padding: 5px;
    }

    .projects-grid .insight {
        background-color: #F5F6F7;
        position: relative;
        cursor: pointer;
    }

        .projects-grid .insight:after {
            padding-top: 80px;
            display: block;
            content: '';
        }

    .projects-grid .insight > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .projects-grid .insight.selected {
        background: rgba(45, 148, 150, 0.2);
    }

        .projects-grid .insight.selected > div:after {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 2.8em;
            color: #0F7193;
            font-family: 'gbs-icons';
            content: '\e614';
        }

        .projects-grid .insight div.scale {
            float: left;
            height: 68px;
            width: 68px;
            margin: 6px;
            background-size: cover;
            background-position: center center;
        }

        .projects-grid .insight div.name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            float: right;
            margin: 18px 10px 18px 4px;
            width: calc(100% - 94px);
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            height: 44px;
            line-height: 1.36em;
            font-size: 1.6em;
            color: #0f7193;
        }