#lmv {
    height: 100%;
    position: relative;
}

#map {
    height: calc(100% - 4.2em);
    position: relative;
}

.col-padding {
    padding: 5px;
}

.overview-widget-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 50%;
    max-width: 100%;
    min-width: 100%;
    min-height: 50vh;
    max-height: 50vh;
}

.overview-widget {
    border-color: #CCCCCC;
    border-width: 1px;
    border-style: solid;
    background: #F7F7F7;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.overview-widget-header {
    margin: 1.1em;
}

    .overview-widget-header span {
        font-size: 14px;
        font-weight: 600;
        font-family: ArtifaktElement,sans-serif;
    }
