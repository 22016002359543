.my-metrics-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f5f5f5;
  padding: 0.6rem;
  width: 240px;
  overflow: hidden;
}

.metrics-list-wrapper {
  height: 100%;
  background-color: white;
  flex: 1;
  overflow: hidden;
}

.metrics-list li {
  margin-left: 10px;
}

.metrics-list li > div {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.metrics-list li:first-child {
  margin-top: 0.5rem;
}

.metrics-list {
  overflow-y: hidden;
  height: calc(100% - 3rem);
}

.action-icons {
  display: flex;
  justify-content: space-evenly;
}

.metric-inputs {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

#metric-description-input {
  overflow-y: auto !important;
}

.action-buttons {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  padding: 10px;
}

.formula-creator-wrapper {
  border: 1px solid rgba(60, 60, 60, 0.1);
  margin-top: 1rem;
  padding: 0.2rem;
}

.formula-creator-wrapper p {
  padding: 5px;
}
.formula-creator-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.formula-creator-wrapper .MuiInputBase-input {
  width: 100%;
  height: 8rem;
  max-height: 8rem;
  resize: none;
}

.formula-container {
  height: 100%;
  width: 100%;
  padding: 10px 5px 5px;
  font-size: 27px;
  white-space: pre-wrap;
  line-height: 1.2;
  overflow-y: auto;
}

.formula-container-disabled {
  border: 1px solid lightgray;
  color: #80808040
}

.formula-evaluate-wrapper {
  display: flex;
  justify-content: space-between;
}

.units-row-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

.noMargin {
  margin: 1rem 0 !important
}
.metric-action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.disabled-icon {
  filter: opacity(0.5);
  pointer-events: none;
}

.specialCharactersError {
  display: flex;
  flex-direction: row;
}

.pointer-cursor {
  cursor: pointer;
}

.ellipsisContent {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
