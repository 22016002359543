.info-dialog {
  font-size: 14px;
}

  .info-dialog strong {
    font-weight: 700;
  }

  .info-dialog p {
    margin-bottom: 10px;
    text-align: justify;
  }

    .info-dialog p:last-child {
      margin-bottom: 0;
    }

.menu__button-wrapper {
  cursor: pointer;
}
