.ecdetail-main {
  padding: 20px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ecdetail-title {
  font-size: smaller;
  padding-bottom: 5px;
}

.ecdetail-construction-table-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 50%;
}

.ecdetail-construction-table-container .ecdetail-title {
  flex-shrink: 0;
  height: fit-content;
}

.ecdetail-construction-table-container .ecdetail-table {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ecdetail-table > .hig-table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#construction-data-grid .MuiDataGrid-virtualScroller {
  overflow-y: auto;
  overflow-x: scroll;
}

#construction-data-grid .MuiDataGrid-row div[data-colindex='5'] {
  justify-content: flex-end;
}
#construction-data-grid
  .MuiDataGrid-pinnedRows
  .MuiDataGrid-row
  .MuiDataGrid-cell:first-of-type
  .MuiBox-root {
  justify-content: flex-end;
}
#construction-data-grid .MuiDataGrid-pinnedRows--bottom {
  border-top: 0;
}
#construction-data-grid .MuiDataGrid-pinnedRows {
  background: white;
}
#construction-data-grid .MuiDataGrid-pinnedRows .MuiDataGrid-cell {
  font-weight: 700;
  background: rgba(204, 204, 204, 0.4);
}

#construction-data-grid .MuiDataGrid-treeDataGroupingCell .MuiBox-root {
  width: calc(100% - 25px);
}
#construction-data-grid .MuiDataGrid-treeDataGroupingCell .MuiBox-root > div {
  display: contents;
}

#construction-data-grid.quick-is-open .MuiDataGrid-virtualScroller {
  overflow: hidden;
  pointer-events: none;
}

div.MuiDataGrid-pinnedRowsRenderZone div div[aria-colindex='1'] {
  padding: 0;
}

div.MuiDataGrid-pinnedRowsRenderZone
  div
  div[aria-colindex='1']
  .MuiDataGrid-treeDataGroupingCellToggle {
  margin: 0;
}

#construction-data-grid
  .MuiDataGrid-pinnedRows
  .MuiDataGrid-row
  .MuiDataGrid-cell:first-of-type
  .MuiBox-root {
  margin-right: 7px;
}

.construction-table > .hig-table-wrapper > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.construction-table-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.construction-table .construction-table-header {
  height: fit-content;
  width: 100%;
  line-height: 15px;
}

.construction-table .construction-table-header .construction-table-header-tile {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.construction-table .construction-table-header .construction-table-header-unit {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 1rem;
}

.ecdetails-content-lmv-container {
  width: 100%;
  display: flex;
  height: 50%;
}

.ecdetails-lmv-section-wrapper {
  width: 100%;
  display: flex;
  flex-shrink: 0;
}

.ecdetails-lmv-section-wrapper > *:nth-child(1) {
  flex: 1 1 72%;
  background-color: #ededed;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px 0px 0px 6px;
}

.ecdetails-lmv-section-wrapper > *:nth-child(2) {
  flex: 1 1 28%;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: lightgray;
  border-radius: 0px 6px 6px 0px;
  background-color: #eee;
  overflow: hidden;
}

.ecdetails-content-lmv-legend {
  position: relative;
  flex: 1 1 28%;
  max-width: 28%;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: lightgray;
  border-radius: 0px 6px 6px 0px;
  background-color: #eee;
  overflow: hidden;
}
.ecdetails-content-lmv-legend-collapsed {
  position: relative;
  width: 24px;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: lightgray;
  border-radius: 0px 6px 6px 0px;
  background-color: #eee;
  overflow: hidden;
  transition: width ease 0.9s;
}
.ecdetails-lmv-container {
  width: 50%;
  max-width: calc(100% - 20px);
  flex-shrink: 0;
  display: flex;
}
.ecdetails-content-chart-container {
  flex: 1;
  width: 0%;
  position: relative;
}
.ecdetails-content-chart {
  position: relative;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
}
.ecdetails-widget-wrapper {
  position: relative;
  flex: 1 1 72%;
  background-color: #ededed;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px 0px 0px 6px;
}
.ecdetails-widget-wrapper-expanded {
  position: relative;
  flex: 1 1 97%;
  background-color: #ededed;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px 0px 0px 6px;
}
.ecdetails_loader {
  height: 100%;
  display: flex;
  justify-content: center;
}

.ecdetails-chart-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 72%;
  background-color: white;
}

.ecdetails-chart-section-expanded {
  flex: 1;
}

.ecdetails-content-chart-legend {
  flex: 1 1 28%;
  max-width: 28%;
  background-color: #eee;
  border-left: 1px solid lightgray;
}

.ecdetails-content-chart-legend-collapsed {
  width: 24px;
  flex: 0;
}

.ecdetails-chart-header-container {
  display: flex;
  justify-content: flex-start;
}

.ecdetails-chart-div-title {
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;
  padding-top: 24px;
}

.ecdetails-chart-title {
  color: #3c3c3c;
  font-size: large;
  font-weight: bold;
  font-family: ArtifaktElement, sans-serif;
}

.ecdetails-chart-title-units {
  color: #3c3c3c;
  font-size: 10px;
  font-family: ArtifaktElement, sans-serif;
}

.ecdetails-chart-legend {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-right: 10px;
  width: 25%;
}

.ecdetails-chart-legend-label {
  white-space: nowrap;
  padding-right: 4px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: bold;
  font-family: ArtifaktElement, sans-serif;
}

.ecdetails-highchart-container {
  flex: 1;
  position: relative;
}

.ecdetails-highchart-wrapper {
  position: absolute;
  inset: 0;
}

.ecdetails-highchart-wrapper > div {
  height: 100%;
}

.adsk-viewing-viewer #toolbar-modelStructureTool {
  display: none !important;
}

.adsk-viewing-viewer #toolbar-propertiesTool {
  display: none !important;
}

.adsk-viewing-viewer .adsk-button {
  padding: 1px;
  height: 16px;
  width: 16px;
}

.adsk-viewing-viewer .adsk-button-icon {
  font-size: 16px;
  padding-top: 1px;
}

#construction-data-grid .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell span p {
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}
