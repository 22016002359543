.searchContainer {
  display: flex;
}

.searchContainer input {
  margin: 8px;
}

.searchContainer .iconContainer {
  display: flex;
  padding: 8px;
  align-items: center;
  border-bottom: rgba(128, 128, 128, 0.5) solid 1px;
}

.searchContainer .disabledIconContainer {
  display: flex;
  padding: 8px;
  align-items: center;
  border-bottom: #8080802e  solid 1px;
}

.searchContainer .iconContainer .closeIcon:hover {
  cursor: pointer;
}
