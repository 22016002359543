.models-container {
    border: 1px solid #CDC5C4;
    width: 857px;
    height: 500px;
    position: absolute;
    top: auto;
    left: 0;
    top: 20%;
    right: 0;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    z-index: 20000;
}

.models-wrapper {
    height: 100%;
    overflow: hidden;
    background-color: #F5F6F7;
}
    .models-wrapper.no-footer .popup-footer {
        display: none;
    }

.models-header {
    padding: 16px 20px 14px 20px;
    position: relative;
    text-align: center;
}

    .models-header .title {
        font-size: 1.8em;
        font-family: 'RobotoCondensed';
        color: #084053;
        line-height: 0.98em;
    }

    .models-header .icon-delete {
        float: right;
        color: #0f7193;
        cursor: pointer;
        font-size: 2.2em;
        position: absolute;
        top: 13px;
        right: 17px;
    }

    .models-header .body {
        position: absolute;
        top: 16px;
        left: 20px;
    }

.models-body {
    height: calc(100% - 120px);
    overflow-y: auto;
    margin: 0 20px;
    background-color: #FFF;
}

.models-wrapper.no-footer.with-error .popup-body,
.models-wrapper.with-error .popup-body {
    height: calc(100% - 175px);
}

.models-wrapper.no-footer .popup-body {
    height: calc(100% - 70px);
}

.models-footer {
    padding: 20px 20px 0 20px;
    height: 70px;
    text-align: right;
}

.model-image {
    width: 66px;
    height: 66px;
    border: 1px solid #dddddd;
    margin: 7px;
}

.model-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    float: right;
    margin: 18px 10px 18px 4px;
    width: calc(100% - 94px);
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 44px;
    line-height: 1.36em;
    font-size: 1.6em;
    color: #0f7193;
}

/* #region add/move design options popup */

.design-options-grid .container-fluid {
    padding: 5px;
}

    .design-options-grid .container-fluid .row.no-gutter > [class*='col-'] {
        padding: 5px;
    }

.design-options-grid .design-option,
.design-options-grid .insight {
    background-color: #F5F6F7;
    position: relative;
    cursor: pointer;
}

    .design-options-grid .design-option:after {
        padding-top: 80px;
        display: block;
        content: '';
    }

    .design-options-grid .design-option > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .design-options-grid .design-option img {
        width: 66px;
        height: 66px;
        border: 1px solid #dddddd;
        margin: 7px;
    }

    .design-options-grid .design-option .progress-ring {
        width: 66px;
        height: 66px;
        border: 1px solid #dddddd;
        margin: 1px;
        display: -webkit-inline-box;
        justify-content: center;
    }

    .design-options-grid .design-option.selected {
        background: rgba(45, 148, 150, 0.2);
    }

        .design-options-grid .design-option.selected > div:after {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 2.8em;
            color: #0F7193;
            font-family: 'gbs-icons';
            content: '\e614';
        }

    .design-options-grid .insight div.name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        float: right;
        margin: 18px 10px 18px 4px;
        width: calc(100% - 94px);
        word-break: break-all;
        word-wrap: break-word;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        height: 44px;
        line-height: 1.36em;
        font-size: 1.6em;
        color: #0f7193;
    }

/* #endregion */