.btn {
  font-family: 'Roboto';
  font-size: 14px;
  background: #0f7193;
  border-radius: 0;
  color: #ffffff !important;
  min-width: 90px;
  border: none;
}

.btn:focus {
  color: #ffffff;
}

.btn:hover {
  background: #ffffff;
  color: #0f7193 !important;
  outline: 1px solid #0f7193;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.btn.cancel {
  background: #a49694;
}

.btn.cancel.disabled {
  color: #ededed;
  background: #cbc4c3;
  opacity: 1;
}

.btn.cancel:hover {
  background: #ffffff;
  color: #a49694;
  outline: 1px solid #a49694;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.btn.disabled {
  color: #ededed;
  outline: 0;
  background: #87b8c9;
  opacity: 1;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
  pointer-events: none;
}
