.overview-oc-main {
    padding: 20px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.overview-oc-top-container {
    width: 100%;
    display: flex;
}

.overview-oc-top-content {
    width: 100%;
}

.overview-oc-insight {
    width: 70%;
}

.overview-oc-table-container {
    width: 356px;
    height: 94px;
    min-width: 356px;
}

.overview-oc-bottom-container {
    width: 100%;
    display: flex;
    height: 81%;
}

.overview-oc-bottom-content {
    width: 100%;
}

.overview-oc-performance-container {
    width: 100%;
    height: 100%;
}

.overview-oc-viewer-container {
    width: 356px;
    min-height: 416px;
    height: 100%;
    min-width: 356px;
}

.overview-oc-content-divider {
    display: flex;
    width: 100%;
    border: 1px solid rgba(60, 60, 60, 0.1);
}

.overview-oc-performance-metric-container {
    width: 100%;
    height: 100%;
    display: flex;
    min-width: 980px;
    margin-right: 12px;
}

.overview-oc-performance-metric {
    width: 20%;    
    height: 100%;
    min-height: 416px;
}

.overview-oc-viewer-content {
    border: 1px solid rgba(60, 60, 60, 0.1);
    width: 100%;
    height: 100%;
}

.overview-oc-header {
    font-weight: 600;
    text-align: left;
    height: 24px;
    width: 100%;
    font-size: 14px;
    padding-top: 3px;
}

.overview-oc-performance-container-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
}

.overview-oc-insight-content-wrapper {
    width: 100%;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
}

.overview-oc-insight-content {
    width: 100%;
    height: 94px;
    overflow-y: auto;
    margin-right: 12px;
    font-size: 14px;
}

.high-density-spacing-right {
    margin-right: 12px;
}

.high-density-spacing {
    padding: 8px;
}

.high-density-spacing-top {
    margin-top: 8px;
}

.oc-border-left {
    border-left: 1px solid rgba(60, 60, 60, 0.1);
}

.oc-border-right {
  border-right: 1px solid rgba(60, 60, 60, 0.1);
}
