.main-left-panel {
    color: #3c3c3c;
    width: 100%;
    background-color: #d9d9d9;
    z-index: 100;
    padding: 12px 0px 0px 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

    .main-left-panel.collapsed {
        padding: 12px 0px 0px 4px;
        align-items: center;
    }

.left-panel-models-container {
    position: relative;
    overflow: auto;
    padding-right: 12px;
    padding-left: 8px;
    scrollbar-color: rgba(128, 128, 128, 0.2) #d9d9d9 !important;
}

.left-panel-header {
    padding: 4px 8px 12px 4px;
}

.left-panel-model-thumbnail {
    position: relative;
    width: 100%;
    height: 144px;
    padding: 12px 20px 36px 20px;
    margin-bottom: 12px;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .left-panel-model-thumbnail:hover {
        background-color: rgba(128, 128, 128, 0.1);
        cursor: pointer;
    }

    .left-panel-model-thumbnail:active {
        background-color: rgba(128, 128, 128, 0.2);
    }

    .left-panel-model-thumbnail > img {
        height: 96px;
        width: 100%;
        margin-bottom: 12px;
        background-color: #ebebeb;
        border-radius: 2px;
        border: 1px solid rgba(128, 128, 128, 0.6);
    }

    .left-panel-model-thumbnail > p {
        font-size: 12px;
        font-weight: 600;
        font-family: ArtifaktElement,sans-serif;
        white-space: nowrap;
    }

    .left-panel-model-thumbnail.selected {
        background-color: rgba(205, 234, 247, 0.6);
        border: 1px solid rgba(6, 150, 215, 0.5);
    }

        .left-panel-model-thumbnail.selected:hover {
            background-color: rgba(155, 213, 239, 0.7);
            border: 1px solid rgba(6, 150, 215, 0.5);
        }

        .left-panel-model-thumbnail.selected:active {
            background-color: rgba(106, 192, 231, 0.7);
            border: 1px solid rgba(6, 150, 215, 0.5);
        }

.left-panel-model-thumbnail-progress-ring {
    height: 96px;
    width: 100%;
    margin-bottom: 12px;
    background-color: #ebebeb;
    border-radius: 2px;
    border: 1px solid rgba(128, 128, 128, 0.6);
    display: flex;
    justify-content: center;
}

.model-widget-dropdown {
    max-width: 122px;
    font-size: 12px !important;
    font-weight: bold;
    font-family: ArtifaktElement,sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropDown-icons {
    margin-right: 4px;
}

.left-panel-footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

    .left-panel-footer.collapsed {
        border-top: unset;
        width: 100%;
        padding-right: 4px;
    }

.left-panel-footer-divider {
    border-top: 1px solid rgba(128, 128, 128, 0.35);
    margin: 4px 4px 4px 0px;
}

.collapse-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    padding-left: 4px;
    padding-bottom: 4px;
}

.thumbnail-ecr-value {
    position: absolute;
    top: 8px;
    right: 9px;
    height: 20px;
    width: 35px;
    border-radius: 11px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    padding: 3px;
}

    .thumbnail-ecr-value.green {
        background-color: #507b16;
        color: white;
    }

    .thumbnail-ecr-value.yellow {
        background-color: #FAA21B;
    }

    .thumbnail-ecr-value.red {
        background-color: #AF1b1b;
        color: white;
    }

.dragable-panel-container {
    width: 6px;
    right: 0px;
    top: 0px;
    position: absolute;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.1);
}

    .dragable-panel-container:hover {
        cursor: pointer;
    }

    .dragable-panel-container:active {
        background-color: rgba(128, 128, 128, 0.35);
    }

.dragable-panel-icon {
    height: 16px;
    width: 6px;
    display: block;
    position: absolute;
    right: 1px;
    top: calc(100%/2);
}

.left-panel-dropdown {
    cursor: pointer;
    color: #3c3c3c;
    font-family: ArtifaktElement,sans-serif;
    font-weight: 400;
    font-size: 14px;
    height: 29px;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 10px;
}

    .left-panel-dropdown:hover {
        background-color: rgba(128, 128, 128, 0.35);
    }

.left-panel-navlink {
    padding: 0;
}
