/* Panel styles*/
.addECDefinitionContainer {
  display: grid;
  grid-template-rows: 1fr 50px;
  height: 100%;
}

.addECDefinitionPanelsContainer {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  grid-column-gap: 50px;
  height: 100%;
  overflow: hidden;
}

.addECDefinitionLeftPanel {
  height: 100%;
}

.addECDefinitionRightPanel {
  height: 100%;
  overflow: hidden;
}
.addECDefinitionLeftPanel .coefficientInputContainer,
.addECDefinitionLeftPanel .sourceInputContainer,
.addECDefinitionLeftPanel .nameInputContainer {
  margin-bottom: 25px;
}

.addECDefinitionLeftPanel .MuiFormLabel-root {
  margin-top: 2px;
  margin-bottom: 2px;
}

.addECDefinitionLeftPanel .sourceInputContainer {
  margin-top: -20px;
}

/* Label styles*/
.defaultLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Name Input Styles */
.inputWithError {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* Coefficient Input styles*/
.coefficientInputContainer {
  margin-top: 25px;
}

.coefficientInputContainer .coefficientInputItems {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.coefficientInputItems .MuiSelect-select {
  margin-bottom: 0;
}

.coefficientContainer {
  display: flex;
  flex-direction: column;
  height: 6rem;
}

.coefficientVerticalLine {
  border-right: 1px solid rgba(128, 128, 128, 0.5);
  height: 20px;
  margin-left: 8px;
  margin-right: 8px;
}

.numericInput div:first-child {
  display: none;
}

.dropdownOption {
  margin: 10px;
  padding: 0px;
}

.dropdownOption:hover {
  cursor: pointer;
  background: rgba(204, 204, 204, 0.4);
}

/* Source Input Styles */

/* Notes Input Styles */
.notesInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notesInputContainer .inputWithError {
  height: 8.4rem;
}

.notesInputContainer .MuiInputBase-input {
  width: 100%;
  height: 8.4rem !important;
  max-height: 8.4rem !important;
  resize: none;
  overflow: auto;
}

.MuiFormControl-root + div {
  width: auto;
}

/* Labels Input Styles */
.labelsContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  border: 1px solid rgba(60, 60, 60, 0.1);
  border-bottom: none;
  padding: 10px;
  overflow: auto;
}

.labelContainer {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
}

.labelsInputContainer {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.labelsHeader {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  height: 24px;
  background: rgba(204, 204, 204, 0.4);
}

.checkedLabel {
  font-weight: bold;
}

.addLabelInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(60, 60, 60, 0.1);
  border-top: none;
  margin-bottom: 25px;
}

.addLabelInputContainer p {
  padding-bottom: 10px;
  margin-top: 2px;
}

.addLabelInputSeparator {
  width: 90%;
  border-top: 1px solid rgba(60, 60, 60, 0.1);
  margin: 10px;
}

.addLabelInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.addLabelInputWithError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  align-items: center;
  width: 100%;
}

.addLabelInput .MuiButtonBase-root {
  min-width: 0;
  margin: 0 0 0 8px !important;
}

.addNewLabelWarningIcon {
  margin-top: 2px;
  margin-right: 5px;
}

.addNewLabelErrorMessageAndWarning {
  display: flex;
  margin-bottom: 6px;
  width: 89%;
}

.errorMessageAndIcon {
  display: flex;
  align-items: center;
}

.addECDefinitionContainer .errorIcon {
  margin-top: 2px;
}

/* Buttons Container Styles*/
.addECDefinitionButtons {
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-content: center;
  padding: 10px;
}

.buttonsContainer {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}

/* Source Table Cell Styles */
.sourceContentWrapper {
  display: flex;
}

.sourceContentWrapper a,
.sourceContentWrapper p {
  margin-right: 3px;
}

.addLabelInput .MuiSvgIcon-root {
  display: none;
}

.coefficientInputItems .MuiFormControl-root svg:first-child {
  display: none;
}

.addLabelInput div {
  margin-right: 0px;
}

.nameInputContainer .inputWithError {
  margin-bottom: 0;
}

.nameInputContainer .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  width: 100%;
}

.addLabelInputContainer .MuiSvgIcon-root {
  margin-bottom: 8px;
}
