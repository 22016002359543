@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(../fonts/roboto-normal.woff) format('woff');
}

@font-face {
    font-family: 'Roboto-Light';
    src: local('Roboto-Light'), url(../fonts/roboto-light.woff) format('woff');
}

@font-face {
    font-family: 'RobotoCondensed';
    src: local('Roboto-Light'), url(../fonts/roboto-condensed-normal.woff) format('woff');
}

@font-face {
    font-family: 'RobotoCondensed-Bold';
    src: url('../fonts/roboto-condensed-bold.woff2') format('woff2'), url('../fonts/roboto-condensed-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gbs-icons';
    src: url('../fonts/gbs-icons.eot');
    src: url('../fonts/gbs-icons.eot') format('embedded-opentype'), url('../fonts/gbs-icons.ttf') format('truetype'), url('../fonts/gbs-icons.woff') format('woff'), url('../fonts/gbs-icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gbs-stockicons';
    src: url('../fonts/gbs-stockicons.eot');
    src: url('../fonts/gbs-stockicons.eot') format('embedded-opentype'), url('../fonts/gbs-stockicons.ttf') format('truetype'), url('../fonts/gbs-stockicons.woff') format('woff'), url('../fonts/gbs-stockicons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-condensed-bold';
    src: url('../fonts/helvetica-condensed-bold-webfont.eot');
    src: url('../fonts/helvetica-condensed-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helvetica-condensed-bold-webfont.woff') format('woff'), url('../fonts/helvetica-condensed-bold-webfont.ttf') format('truetype'), url('../fonts/helvetica-condensed-bold-webfont.svg#helvetica-condensed-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-condensed-light';
    src: url('../fonts/helvetica-lightcondensed-webfont.eot');
    src: url('../fonts/helvetica-lightcondensed-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helvetica-lightcondensed-webfont.woff') format('woff'), url('../fonts/helvetica-lightcondensed-webfont.ttf') format('truetype'), url('../fonts/helvetica-lightcondensed-webfont.svg#helvetica-condensed-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gbs-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="stockicon-"], [class*=" stockicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gbs-stockicons' !important;
    position: relative;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@-moz-document url-prefix() {
    [class^="icon-"], [class*=" icon-"], [class^="stockicon-"], [class*=" stockicon-"] {
        cursor: default;
    }
}

.icon-sign-in:before {
    content: "\e900";
}

.stockicon-question-mark:before {
    content: "\e902";
}

.stockicon-question-mark:after {
    content: "\e903";
}

.icon-main-menu:before {
    content: "\e695";
}

.icon-collection:before {
    content: "\e62d";
}

.icon-view:before {
    content: "\e63d";
}

.icon-comments:before {
    content: "\e683";
}

.icon-time-activity:before {
    content: "\e613";
}

.icon-plus:before {
    content: "\e681";
}

.icon-vertical-kebab-menu:before {
    content: "\e600";
}

.icon-edit:before {
    content: "\e606";
}

.icon-camera:before {
    content: "\e686";
}

.icon-trash:before {
    content: "\e604";
}

.icon-ok:before {
    content: "\e614";
}

.icon-thumbnail-view:before {
    content: "\e601";
}

.icon-list-view:before {
    content: "\e602";
}

.icon-move-to-folder:before {
    content: "\e61d";
}

.icon-retrofit:before {
    content: "\e908";
}

.icon-share-content:before {
    content: "\e904";
}

.icon-paste:before {
    content: "\e658";
}

.icon-delete:before {
    content: "\e60a";
}