.progress-bar-panel {
    height: 100%;
    background-color: #eee;
    border-color: #eee;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
