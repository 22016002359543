.factor-widget {
    background: #fff !important;
}

.factor-modal-container {
    border: 1px solid #CDC5C4;
    width: 500px;
    height: 600px;
    position: absolute;
    top: auto;
    left: 0;
    top: 5%;
    right: 0;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    z-index: 20000;
}

.factor-chart-wrapper {
    height: 100%;
    overflow: hidden;
    background-color: white;
}

.chart-modal-container {
    padding: 0 10px 0 10px;
    max-width: 400px;
}

.factor-container {
    height: 373px;
}

.gauge-container {
    height: 350px;
}


.factor-tooltip-name {
    /*color: #0696d7;*/
    font-size: 11px;
    letter-spacing: -0.02em;
    font-weight: 500;
}

.factor-tooltip-label, .factor-tooltip-unit {
    font-size: 15px;
    letter-spacing: -0.02em;
}

.factor-tooltip-value {
    font: 15px;
}

.factor-tooltip-unit {
    font-size: 12px;
}

.chart-button {
    margin-left: auto;
    margin-right: 12px;
    border: none;
    border-radius: 5px;
    width:25px;
}

    .chart-button:focus {
        outline: none;
    }

.custom-plot-band:hover {
    fill: rgba(128, 128, 128, 0.1);
}

.highcharts-axis-labels span {
    word-break: break-all !important;
    width: 55px !important;
    white-space: normal !important;
}

.range-selector:hover {
    fill: rgba(205, 234, 247, 0.3);
}

.meter-container {
    width: 206px;
    height: 80px;
    border: 1px solid #DBDBDB;
    background-color: #F5F5F5;
    display: flex;
    border-radius: 2px;
    margin-right: 10px;
    cursor: pointer;
}
    .meter-container:hover {
        background-color: rgba(128, 128, 128, 0.1);
    }
    .meter-container:active {
        background-color: rgba(128, 128, 128, 0.2);
    }    
    .meter-container.selected {
        background-color: rgba(205, 234, 247, 0.6);
        border: 1px solid rgba(6, 150, 215, 0.5);
    }
        .meter-container.selected:hover {
            background-color: rgba(155, 213, 239, 0.7);
            border: 1px solid rgba(6, 150, 215, 0.5);
        }
        .meter-container.selected:active {
            background-color: rgba(106, 192, 231, 0.7);
            border: 1px solid rgba(6, 150, 215, 0.5);
        }

.meter-value {
    font-size: 30px;
    font-family: ArtifaktElement,sans-serif;
    font-weight: 700;
    color: #000000;
}

.meter-value-label {
    font-size: 11px;
    font-family: ArtifaktElement,sans-serif;
    font-weight: 500;
    color: #3C3C3C;
}

.meter-min-max-labels {
    font-size: 11px;
    font-family: ArtifaktElement,sans-serif;
    position: relative;
    top: -43px;
    left: 13px;
    display: flex;
    justify-content: space-between;
    width: 88px;
    color: #3c3c3c;
}

.meter-white-plot-band:hover {
    fill: white;
}

.meter-chart-container {
    padding-top: 12px;
}

.meter-values-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
